<template>
  <DetailTemplate
    :customClass="'leave-detail detail-page'"
    v-if="getPermission('leave:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ leaveTitle }}
          </h1>
          <CustomStatus
            :status.sync="leaveDetail.status"
            endpoint="leave/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateMoreAction('edit')"
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <table width="100%">
              <template v-if="leaveDetail.engineer">
                <tr>
                  <td width="300" class="pb-2 font-size-18 text-capitalize">
                    Engineer Display Name
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ leaveDetail.engineer.display_name }}
                  </td>
                </tr>
                <tr>
                  <td width="300" class="pb-2 font-size-18 text-capitalize">
                    Engineer Email
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                    {{ leaveDetail.engineer.user_email }}
                  </td>
                </tr>
                <tr>
                  <td width="300" class="pb-2 font-size-18 text-capitalize">
                    Engineer Phone Number
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ leaveDetail.engineer.phone_number }}
                  </td>
                </tr>
              </template>
              <tr>
                <td width="300" class="pb-2 font-size-18 text-capitalize">
                  Leave Type
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leaveDetail.leave_type">{{
                    leaveDetail.leave_type
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Leave Type</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="300" class="pb-2 font-size-18 text-capitalize">
                  Team Email ID
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="leaveDetail.team_email_id">{{
                    leaveDetail.team_email_id
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Team Email ID
                    </em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Leave #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ leaveDetail.barcode }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Leave Duration
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leaveDetail.leave_duration">{{
                    lodash.toUpper(leaveDetail.leave_duration)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Leave Duration</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Start Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leaveDetail.leave_start_date">{{
                    formatDate(leaveDetail.leave_start_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Start Date</em></template
                  >&nbsp;
                  <template
                    v-if="
                      leaveDetail.leave_duration == 'half-day' &&
                      leaveDetail.leave_start_time
                    "
                    >{{ formatTime(leaveDetail.leave_start_time) }}</template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  End Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leaveDetail.leave_end_date">{{
                    formatDate(leaveDetail.leave_end_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No End Date</em></template
                  >&nbsp;
                  <template
                    v-if="
                      leaveDetail.leave_duration == 'half-day' &&
                      leaveDetail.leave_end_time
                    "
                    >{{ formatTime(leaveDetail.leave_end_time) }}</template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            md="12"
            class="py-0"
            content="Reason"
            v-tippy="{ placement: 'top-start' }"
          >
            <read-more
              class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
              more-str="read more"
              :text="leaveDetail.description || '<em>No Reason</em>'"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </v-col>
          <v-col md="12">
            <FileTemplate
              :allowDelete="false"
              :attachments.sync="attachments"
            ></FileTemplate>
          </v-col>
        </v-row>
      </v-container>
      <LeaveDelete
        :deleteDialog.sync="leaveDeleteDialog"
        :requestUrl.sync="leaveDeleteRequestUrl"
        v-on:delete:success="leaveDeleteSuccess"
        v-on:delete:close="leaveDeleteDialog = false"
      ></LeaveDelete>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import LeaveDelete from "@/view/pages/partials/Delete-Request-Template.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "leave-detail",
  title: "Detail Leave",
  data() {
    return {
      leave: null,
      leaveDeleteDialog: false,
      moreActions: new Array(),
      attachments: new Array(),
      pageLoading: false,
      leaveDetail: new Object({
        barcode: null,
        engineer: null,
        leave_duration: null,
        leave_type: null,
        leave_start_date: null,
        leave_end_date: null,
        leave_start_time: null,
        leave_end_time: null,
        reason: null,
        status: null,
        team_email_id: null,
        attachments: [],
      }),
    };
  },
  components: {
    DetailTemplate,
    CustomStatus,
    LeaveDelete,
    FileTemplate,
  },
  methods: {
    leaveDeleteSuccess() {
      this.leaveDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("leave.update", {
              params: {
                id: _this.leave,
              },
            })
          );
          break;
        case "delete":
          _this.leaveDeleteDialog = true;
          break;
        case "mark_as_approved":
          _this.updateLeave({ status: 2 });
          break;
        case "mark_as_rejected":
          _this.updateLeave({ status: 3 });
          break;
      }
    },
    updateLeave(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leave/" + _this.leave,
          data: data,
        })
        .then(() => {
          _this.getLeave();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLeave() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.leave,
        })
        .then(({ data }) => {
          _this.leaveDetail = new Object({
            barcode: data.barcode,
            engineer: data.engineer,
            leave_duration: data.duration,
            leave_type: data.type,
            leave_start_date: data.start_date,
            leave_end_date: data.end_date,
            leave_start_time: data.start_time,
            leave_end_time: data.end_time,
            reason: data.reason,
            status: data.status,
            team_email_id: data.email,
            attachments: [],
          });
          _this.attachments = data.attachments;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "Detail" },
    ]);

    _this.leave = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.leave <= 0) {
      _this.goBack();
    }

    _this.getLeave();
  },
  computed: {
    leaveDeleteRequestUrl() {
      return "leave/" + this.leave;
    },
    leaveTitle() {
      return this.leaveDetail.leave_type;
    },
  },
};
</script>
